import { Percent } from '@uniswap/sdk-core'
import { BigNumber } from 'ethers'
import JSBI from 'jsbi'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(400000)

export const NetworkContextName = 'NETWORK'

export const IS_IN_IFRAME = window.parent !== window

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BAD_POOLS_ARRAY = [
  "0xa7eDd9580DEc40B985d3167Dfafb76b32816Df3B",
  "0xd33669D0A6eb343b8d7643DC270b99116e900E64",
  "0x741f3E1f10a66AF9b198A8F5974d83FcF03f9842",
  "0xAcC33801Ae00a342A2fBb34B220264Cc4a028634",
  "0x7fAD8444688C4babb792E960359EA467Fd28d030",
  "0xf99D1DF2DE3684bCaFfA5c9Bcb4b725a7292d56f",
  // polygon bad pools
  "0x01C70a0D2E8b744e5D3003d27916956E41B66745",
  "0x0993818F42EFc0D87e2A046F36b8f9cD0De9c605",
  "0x14744fE2a89a06a08d0932Fbd87015918Ebe800b",
  "0x15c55E6Ed0Aa7A511DAc3736600937ED6b5b8D71",
  "0x206817007467002A9Cd5311C511Eb6E7749763E7",
  "0x2D3ee37bc45b3192Ddc15c5412cca16b7D091D47",
  "0x3045e0f7ebDE8f175B5781eeF0B200cd11613516",
  "0x3412Da41cA59d41ed99Cfa6A6D851A600F8A0815",
  "0x342D64F97F1DCc14E8d2B35d55A5BDC1dD899Db9"
]
