import React from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import { ButtonSmall } from '../../components/Button'
import styled from 'styled-components'
import { ReactComponent as BackgroundImage } from './BackgroundImage.svg'
import { useIsDarkMode } from '../../state/user/hooks'


export const Container = styled.div<{ margin?: string }>`
  border-radius: 20px;
  display: flex;
  align-items: center;  
  padding: 20px 18px;
  max-width: 500px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  
  > * {
   z-index: 1;
  }
`

const ClaimBanner = ({ account, revokeTokens, balances }: any) => {
  const history = useHistory()

  const darkMode = useIsDarkMode()

  return (
    <Container
      style={{
        background: darkMode ? 'linear-gradient(180deg, #CA0060 0%, #222429 44.38%)' : 'linear-gradient(180deg, #2D252E 0%, #222429 100%)'
      }}
    >
      <BackgroundImage
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 0,
        }}
      />
      <TYPE.heading1 mb={2} mt={2} style={{ color: 'white' }}>Attention!</TYPE.heading1>
      <TYPE.description
        mb={3}
        mt={2}
        style={{ maxWidth: '100%', color: 'white' }}
      >
        The funds in your current wallet are not safe.
      </TYPE.description>
      {/*<TYPE.description mb={3} mt={2} style={{ color: 'white' }}>
        <GlobalLink
          href={'/'}
          style={{ color: '#3b8cff' }}
        >
          Click here
        </GlobalLink> for more information.</TYPE.description>*/}
      <TYPE.description
        mb={3}
        mt={2}
        style={{ maxWidth: '100%', color: 'white', marginBottom: '2rem' }}
      >
        Follow these steps to claim back your funds and revoke all outstanding approvals.
      </TYPE.description>
      {revokeTokens && revokeTokens.length > 0 ? <ButtonSmall
        onClick={() => history.push('/revoke-tokens')}
        style={{ width: '100%', maxWidth: '300px', padding: '10px', marginBottom: '2rem' }}
      >
        Revoke approvals{balances.find((a: any) => a.amount !== 0.0) ? ' and claim tokens' : ''}
      </ButtonSmall> : <ButtonSmall
        onClick={() => history.push('/claim-tokens')}
        style={{ width: '100%', maxWidth: '300px', padding: '10px', marginBottom: '2rem' }}
      >
        Claim your tokens
      </ButtonSmall>}
    </Container>
  )
}

export default ClaimBanner
