import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import { ButtonMedium } from '../../components/Button'
import styled from 'styled-components'
import useTheme from '../../hooks/useTheme'
import { useGUniClaimContract } from '../../hooks/useContract'
import { tokensWithDecimals } from '../../constants/tokenLists/claimTokens'
import { ethers } from 'ethers'
import { isAddress } from '../../utils'
import { useActiveWeb3React } from '../../hooks/web3'
import { RiskTokenItem } from './RiskTokenItem'
import { useWalletModalToggle } from 'state/application/hooks'
import { useRevokePools } from 'hooks/useRevokePools'

export const Input = styled.input`
  position: relative;
  display: flex;
  padding: 14px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.bg8};
  border: none;
  outline: none;
  border-radius: 20px;
  color: ${({ theme }) => theme.text1};
  border-style: solid;
  border: 1px solid ${({ theme }) => theme.bg3};
  -webkit-appearance: none;

  font-size: 18px;

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.green1};
    outline: none;
  }
`

const CheckWalletPage: React.FC = () => {
  const { chainId, account } = useActiveWeb3React()
  const history = useHistory()
  const theme = useTheme()
  const [walletAddressInput, setWalletAddressInput] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [walletState, setWalletState] = useState('not-checked')
  const [checking, setChecking] = useState(false)
  const toggleWalletConnect = useWalletModalToggle()
  const filteredPoolsWithBalances = useRevokePools(walletAddress)

  const guniClaim = useGUniClaimContract()
  const [balances, setBalances] = useState<any[]>([])
  
  useEffect(() => {
    const getClaims = async () => {
      if (guniClaim) {
        const amounts = await Promise.all(
          tokensWithDecimals.map(async token => {
            return new Promise(async (resolve) => {
              const tx = await guniClaim.amountWasAtRisk(token.tokenAddress, walletAddress)
              resolve(
                {
                  token: token.tokenAddress,
                  amount: parseFloat(ethers.utils.formatUnits(tx, 6))
                }
              )
            })
          })
        )
        setBalances(amounts)
        setChecking(false)
      }
    }

    if (walletAddress && isAddress(walletAddress)) {
      getClaims()
    } else {
      setChecking(false)
    }
  }, [guniClaim, walletAddress])

  function handleChangeWallet(addressValue: string) {
    setChecking(false)
    setWalletAddressInput(addressValue)
    setWalletState('not-checked')
    if(isAddress(addressValue)) {
      checkWallet(addressValue)
    } else {
      setWalletAddress('')
    }
  }

  const checkWallet = useCallback((addressValue: string) => {
    setWalletAddress(addressValue)
    setChecking(true)
  }, [])

  useEffect(() => {
    setWalletState('not-checked')
    if(account) {
      setWalletAddressInput(account)
      checkWallet(account || '')
    }
  }, [chainId, account, checkWallet])

  useEffect(() => {
    if (walletAddress && !checking) {
      (
        (filteredPoolsWithBalances && filteredPoolsWithBalances.length > 0)
        || (balances.find((a: any) => a.amount !== 0.0))
      )
        ? setWalletState('unsafe')
        : setWalletState('safe')
    }
  }, [filteredPoolsWithBalances, balances, walletAddress, checking])

  return (
    <div style={{ maxWidth: '480px', width: '100%', paddingBottom: '3rem' }}>
      <ButtonMedium
        onClick={() => history.push('/')}
        style={{ width: '100%', maxWidth: '130px', padding: '10px', marginBottom: '2rem' }}
      >
        ← Back
      </ButtonMedium>
      <TYPE.heading1 mb={2} mt={2}>{chainId === 1 ? 'Check if your funds are at risk' : 'Switch to mainnet!'}</TYPE.heading1>
      <TYPE.description mb={4} mt={2}>Enter each of your addresses in turn to check if you need to revoke approvals or claim tokens.</TYPE.description>
      <div style={{ position: 'relative' }}>
        <Input
          value={walletAddressInput}
          onChange={(e) => handleChangeWallet(e.target.value)}
        />
      </div>
      {!isAddress(walletAddressInput) && <TYPE.description mb={4} mt={3} style={{ color: theme.text3 }}>Please enter a valid address</TYPE.description>}
      {checking && <TYPE.description mb={4} mt={3} style={{ color: theme.text3 }}>Checking...</TYPE.description>}
      {
        (!checking && walletState !== 'not-checked') &&
        <>
        {walletState === 'safe' &&
          <TYPE.description
            mb={4}
            mt={3}
            style={{ color: theme.green1 }}
          >
          The entered address is safe!
        </TYPE.description>}
        {walletState === 'unsafe' &&
          <>
            <TYPE.heading1
              mb={2}
              mt={2}
              style={{ color: theme.red2 }}>
              The funds in this address are unsafe!
            </TYPE.heading1>
            <TYPE.description
              mb={4}
              mt={3}
              style={{ color: theme.red2 }}
            >
              Please <strong>connect this wallet</strong> and follow the steps to revoke all the outstanding approvals:
            </TYPE.description>
            {
              account &&
              <ButtonMedium
                disabled={chainId !== 1 || walletAddress !== account}
                onClick={() => history.push('/revoke-tokens')}
                style={{ width: '100%', padding: '20px', marginBottom: '2rem' }}
              >
                { chainId === 1 ? (walletAddress === account) ? 'Secure your funds' : 'Switch account' : 'Switch to mainnet' }
              </ButtonMedium>
            }
            {
              !account &&
              <ButtonMedium
                onClick={() => toggleWalletConnect()}
                style={{ width: '100%', padding: '20px', marginBottom: '2rem' }}
              >
                Connect wallet
              </ButtonMedium>
            }
          </>
        }
        </>
      }
      {
        filteredPoolsWithBalances.map(token => {
          if(token) {
            return <RiskTokenItem
              key={token.token}
              tokenAddress={token.token}
              walletAddress={walletAddress}
            />
          } else {
            return null
          }
        })
      }
    </div>
  )
}

export default CheckWalletPage
