import React, { ReactNode, FC } from "react";
import { Handler } from "./types";
import { Web3Provider } from "../../web3";
import ApplicationUpdater from "state/application/updater";
import ListsUpdater from "state/lists/updater";
import MulticallUpdater from "state/multicall/updater";
import TransactionUpdater from "state/transactions/updater";
import UserUpdater from 'state/user/updater'
import PoolUpdater from 'state/pool/updater'
import { useActiveWeb3React } from "hooks/web3";
import { isNetworkSupported } from "utils/isNetworkSupported";

interface NetworkGuardProps {
  children: ReactNode;
}

const NetworkGuard: FC<NetworkGuardProps> = ({ children }: NetworkGuardProps) => {
  const { chainId } = useActiveWeb3React();
  const netSupport = chainId ? isNetworkSupported(chainId) : false;
  return (
    netSupport ? <>{children}</> : <h1>Unsupported Network</h1>
  )
}
export function GelatoProvider({
  chainId,
  library,
  children,
  account,
  handler,
  toggleWalletModal,
}: {
  chainId: number | undefined;
  library: any | undefined;
  account: string | undefined;
  handler?: Handler;
  toggleWalletModal?: () => void;
  children?: React.ReactNode;
}) {
  return (
    <NetworkGuard>
      <Web3Provider
        chainId={chainId}
        library={library}
        account={account}
        handler={handler}
        toggleWalletModal={toggleWalletModal}
      >
        <UserUpdater />
        <PoolUpdater />
        <ListsUpdater />
        <ApplicationUpdater />
        <MulticallUpdater />
        <TransactionUpdater />
        {children}
      </Web3Provider>
    </NetworkGuard>
  );
}