import { Web3Provider } from '@ethersproject/providers'
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import getLibrary from '../utils/getLibrary'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import SORBET_LOGO_URL from '../assets/svg/logo.svg'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
const ALCHEMY_ID = process.env.REACT_APP_ALCHEMY_ID
const ALCHEMY_ID_OPTIMISM = process.env.REACT_APP_ALCHEMY_ID_OPTIMISM
const ALCHEMY_ID_POLYGON = process.env.REACT_APP_ALCHEMY_ID_POLYGON
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const DEFAULT_NETWORK_ID = Number(process.env.REACT_APP_DEFAULT_NETWORK_ID || 1)

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

if (typeof ALCHEMY_ID === 'undefined') {
  throw new Error(`REACT_APP_ALCHEMY_ID must be a defined environment variable`)
}

const NETWORK_URLS: {
  [chainId: number]: string
} = {
  [1]: `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_ID}`,
  // [4]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [3]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [5]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  // [42]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [10]: `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_ID_OPTIMISM}`,
  [56]: 'https://bsc-dataseed.binance.org',
  [137]: `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_ID_POLYGON}`,
  [43114]: 'https://api.avax.network/ext/bc/C/rpc'
}

const SUPPORTED_CHAIN_IDS = [1, 3, 5, 10, 56, 137, 43114]

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: DEFAULT_NETWORK_ID,
  pollingInterval: 5000
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS
})

export const gnosisSafe = new SafeAppConnector()

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URLS[1],
  appName: 'Sorbet',
  appLogoUrl: SORBET_LOGO_URL
})
