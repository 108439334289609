export const tokensWithDecimals = [
    { tokenAddress: '0x02f88164060912ee44ba9480d05d462d20cff3bc', decimals: 18 },
    { tokenAddress: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919', decimals: 18 },
    { tokenAddress: '0x07baa98c5cdec970a3a4654f0fbb066404ff5fe0', decimals: 18 },
    { tokenAddress: '0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05', decimals: 18 },
    { tokenAddress: '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8', decimals: 18 },
    { tokenAddress: '0x2bd9f7974bc0e4cb19b8813f8be6034f3e772add', decimals: 18 },
    { tokenAddress: '0x3402e15b3ea0f1aec2679c4be4c6d051cef93953', decimals: 18 },
    { tokenAddress: '0x383518188c0c6d7730d91b2c03a03c837814a899', decimals: 9 },
    { tokenAddress: '0x3a226284a0203ed16426f885d8596ae244b169a9', decimals: 18 },
    { tokenAddress: '0x3d1556e84783672f2a3bd187a592520291442539', decimals: 18 },
    { tokenAddress: '0x4e807e056354de33b619699f8491ca8dbd67920d', decimals: 18 },
    { tokenAddress: '0x4f38892c16bfbb4f4f7424eefaa9767f4e922073', decimals: 18 },
    { tokenAddress: '0x50379f632ca68d36e50cfbc8f78fe16bd1499d1e', decimals: 18 },
    { tokenAddress: '0x6b175474e89094c44da98b954eedeac495271d0f', decimals: 18 },
    { tokenAddress: '0x77cdd9e097f34c1fa861daeb6823de741fab2321', decimals: 18 },
    { tokenAddress: '0x7c687f775a3b73bbab0e15832f24caab5d53bdde', decimals: 18 },
    { tokenAddress: '0x7d29a64504629172a429e64183d6673b9dacbfce', decimals: 18 },
    { tokenAddress: '0x853d955acef822db058eb8505911ed77f175b99e', decimals: 18 },
    { tokenAddress: '0x888888888889c00c67689029d7856aac1065ec11', decimals: 18 },
    { tokenAddress: '0x8e870d67f660d95d5be530380d0ec0bd388289e1', decimals: 18 },
    { tokenAddress: '0x90de74265a416e1393a450752175aed98fe11517', decimals: 18 },
    { tokenAddress: '0x956f47f50a910163d8bf957cf5846d573e7f87ca', decimals: 18 },
    { tokenAddress: '0x990f341946a3fdb507ae7e52d17851b87168017c', decimals: 18 },
    { tokenAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', decimals: 6 },
    { tokenAddress: '0xa6c49fd13e50a30c65e6c8480aada132011d0613', decimals: 18 },
    { tokenAddress: '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd', decimals: 18 },
    { tokenAddress: '0xabddafb225e10b90d798bb8a886238fb835e2053', decimals: 18 },
    { tokenAddress: '0xacc33801ae00a342a2fbb34b220264cc4a028634', decimals: 18 },
    { tokenAddress: '0xb05097849bca421a3f51b249ba6cca4af4b97cb9', decimals: 18 },
    { tokenAddress: '0xb1cfdc7370550f5e421e1bf0bf3cadfadf3c4141', decimals: 18 },
    { tokenAddress: '0xbba11b41407df8793a89b44ee4b50afad4508555', decimals: 18 },
    { tokenAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', decimals: 18 },
    { tokenAddress: '0xc11efd085154d8500c61fa988f72ad7c789d5c38', decimals: 18 },
    { tokenAddress: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72', decimals: 18 },
    { tokenAddress: '0xd0cd466b34a24fcb2f87676278af2005ca8a78c4', decimals: 18 },
    { tokenAddress: '0xd33669d0a6eb343b8d7643dc270b99116e900e64', decimals: 18 },
    { tokenAddress: '0xd58c89181360dd9166881fce2bc7c9baae2d5f31', decimals: 18 },
    { tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7', decimals: 6 },
    { tokenAddress: '0xe668fe99123b3e901872a8c310ec2fa076e49fc1', decimals: 18 },
  ]