import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import styled from 'styled-components'
import { ButtonMedium, ButtonSmall } from '../../components/Button'
import './index.css'
import { useGUniRouterContractUnsafe } from '../../hooks/useContract'
import { ethers } from 'ethers'
import { RevokeTokenItem } from './RevokeTokenItem'
import { useMultipleContractSingleData } from 'state/multicall/hooks'
import ERC20ABI from 'abis/erc20.json'
import { Erc20Interface } from 'abis/types/Erc20'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from 'hooks/web3'
import { tokensWithDecimals } from 'constants/tokenLists/claimTokens'
import { useWalletModalToggle } from '../../state/application/hooks'

export const TokenWrapper = styled.div<{ margin?: string }>`
  background: ${({ theme }) => theme.bg1};
  display: flex;
  justify-content: space-between;
  align-items: center;  
  border-radius: 24px;
  padding: 20px 18px;
  margin-top: ${({ margin }) => margin ?? '0px'};
  margin-top: 1rem;
  max-width: 500px;
  position: relative;
  width: 100%;
`


const ClaimPageA: React.FC = () => {
  const history = useHistory()
  const toggleWalletModal = useWalletModalToggle()
  const { account, chainId } = useActiveWeb3React()
  
  const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface;
  const guniRouter = useGUniRouterContractUnsafe()
  const [loading, setLoading] = useState(false)
  const tokens = tokensWithDecimals.map(t => t.tokenAddress)

  const allowances = useMultipleContractSingleData(
    tokens,
    ERC20Interface,
    'allowance',
    [account ? account : '', guniRouter?.address ? guniRouter?.address : ''],
  )
  const poolsWithBalances = allowances.map((allowance, index) => {
    const { result } = allowance;
    if (result) {
      const balance = result[0];
      if (balance.gt(ethers.constants.Zero)) {
        return {
          token: tokens[index],
          balance: balance.toString(),
        }
      }
    }
    return
  })
  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }
  const filteredPoolsWithBalances = poolsWithBalances.filter(notEmpty)

  return (
    <div style={{ maxWidth: '480px', width: '100%', paddingBottom: '3rem' }}>
      {account ? <>
        <TYPE.heading1 mb={2} mt={2}>Step 1/2</TYPE.heading1>
        {!loading && <>
          {filteredPoolsWithBalances.length > 0
            ?
            <>
              <TYPE.heading6 mb={2} mt={2}>Revoke all ERC20 token approvals</TYPE.heading6>
              <TYPE.description mb={2} mt={2}>This is to ensure that all your funds are safe in the future.</TYPE.description>
            </>
            : <>
              <TYPE.heading6 mb={2} mt={2}>All token approvals have been successfully revoked.</TYPE.heading6>
              <TYPE.heading6 mb={2} mt={2}>Your funds are safe.</TYPE.heading6>
            </>}
        </>}
        {
          filteredPoolsWithBalances.map(token => {
            if(token) {
              return <RevokeTokenItem
                key={token.token}
                tokenAddress={token.token}
              />
            } else {
              return null
            }
          })
        }
        {!loading && <>
          {filteredPoolsWithBalances.length === 0 && <ButtonMedium
            onClick={() => history.push('/claim-tokens')}
            style={{ width: '100%', maxWidth: '280px', padding: '10px', marginBottom: '2rem', marginTop: '2rem' }}
          >
            Claim back your funds
          </ButtonMedium>}
        </>}
      </> : <>
        <ButtonSmall onClick={toggleWalletModal} style={{ width: '100%', height: '42px', padding: '10px', marginBottom: '1rem' }}>
          {chainId === 1 ? 'Connect a wallet' : 'Switch to mainnet'}
        </ButtonSmall>
      </>}
    </div>
  )
}

export default ClaimPageA