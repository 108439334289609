import { useCallback } from 'react'
import useDebounce from 'hooks/useDebounce'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useActiveWeb3React } from 'hooks/web3'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../index'
import { NetworkInfo, EthereumNetworkInfo, GoerliNetworkInfo, OptimismNetworkInfo, PolygonNetworkInfo } from 'constants/networks'

import { useBlockNumber } from './hooks'
import { setImplements3085, updateActiveNetworkVersion, updateBlockNumber } from './actions'
import { switchToNetwork } from 'utils/switchToNetwork'

function useBlockWarningTimer() {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const timeout = useRef<NodeJS.Timeout>()
  const isWindowVisible = useIsWindowVisible()
  const [msSinceLastBlock, setMsSinceLastBlock] = useState(0)
  const currentBlock = useBlockNumber()

  useEffect(() => {
    setMsSinceLastBlock(0)
  }, [currentBlock])

  useEffect(() => {
    return function cleanup() {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [chainId, dispatch, isWindowVisible, msSinceLastBlock, setMsSinceLastBlock])
}

export default function Updater(): null {
  const { account, chainId, library } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const windowVisible = useIsWindowVisible()
  const [activeNetworkVersion, setActiveNetworkVersion] = useState<NetworkInfo | undefined>(EthereumNetworkInfo)
  const [blockNumber, setBlockNumber] = useState<number | undefined>(undefined)

  useBlockWarningTimer()

  // attach/detach listeners
  useEffect(() => {
    switch (chainId) {
      case 1:
        setActiveNetworkVersion(EthereumNetworkInfo);
        break;
      case 5:
        setActiveNetworkVersion(GoerliNetworkInfo);
        break;
      case 10:
        setActiveNetworkVersion(OptimismNetworkInfo);
        break;
      case 137:
        setActiveNetworkVersion(PolygonNetworkInfo);
        break;
      default:
        setActiveNetworkVersion(EthereumNetworkInfo);
        break;
    }
  }, [dispatch, chainId, library, windowVisible]);
  
  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setBlockNumber(blockNumber)
    },
    [setBlockNumber]
  );

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined;

    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) =>
        console.error(
          `Failed to get block number for chainId: ${chainId}`,
          error
        )
      );

    library.on("block", blockNumberCallback);
    return () => {
      library.removeListener("block", blockNumberCallback);
    };
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible]);

  const debouncedState = useDebounce({ activeNetworkVersion, blockNumber }, 100)
  useEffect(() => {
    if(debouncedState.activeNetworkVersion) {
      dispatch(
        updateActiveNetworkVersion({ activeNetworkVersion: debouncedState.activeNetworkVersion })
      )
    }
  }, [dispatch, debouncedState.activeNetworkVersion])

  useEffect(() => {
    if (!account || !library?.provider?.request || !library?.provider?.isMetaMask) {
      return
    }
    switchToNetwork({ library })
      .then((x: any) => x ?? dispatch(setImplements3085({ implements3085: true })))
      .catch(() => dispatch(setImplements3085({ implements3085: false })))
  }, [account, chainId, dispatch, library])

  useEffect(() => {
    if (
      !debouncedState.activeNetworkVersion ||
      !debouncedState.blockNumber ||
      !windowVisible ||
      !(chainId === debouncedState.activeNetworkVersion.chainId)
    )
      return;
    
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.activeNetworkVersion.chainId,
        blockNumber: debouncedState.blockNumber,
      })
    );
  }, [
    chainId,
    windowVisible,
    dispatch,
    debouncedState.blockNumber,
    debouncedState.activeNetworkVersion,
  ]);

  return null
}