import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import user from './user/reducer'
import pool from './pool/reducer'
import application from './application/reducer'
import lists from './lists/reducer'
import multicall from './multicall/reducer'
import transactions from './transactions/reducer'
import { gelatoReducers, GELATO_PERSISTED_KEYS } from '@gelatonetwork/limit-orders-react'
import { gelatoRangeOrderReducers, GELATO_RANGE_PERSISTED_KEYS } from '@gelatonetwork/range-orders-react'

const PERSISTED_KEYS: string[] = ['user', 'application', 'lists', ...GELATO_PERSISTED_KEYS, ...GELATO_RANGE_PERSISTED_KEYS]

const store = configureStore({
  reducer: {
    user,
    application,
    lists,
    pool,
    multicall,
    transactions,
    ...gelatoReducers,
    ...gelatoRangeOrderReducers
  },
  middleware: [
    // ...getDefaultMiddleware({ thunk: false }),
    save({ states: PERSISTED_KEYS, debounce: 1000, namespace: "g-uni-pools-v0", namespaceSeparator: "::" }),
  ],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
