import { useMemo } from 'react'
import { ethers } from 'ethers'
import { useGUniRouterContractUnsafe } from 'hooks/useContract'
import { Interface } from '@ethersproject/abi'
import { tokensWithDecimals } from 'constants/tokenLists/claimTokens'
import ERC20ABI from 'abis/erc20.json'
import { Erc20Interface } from 'abis/types/Erc20'
import { useMultipleContractSingleData } from 'state/multicall/hooks'
import { ZERO_ADDRESS } from 'constants/misc'

export function useRevokePools(walletAddress: string): Array<{token: string; balance: string}> {
  const guniRouter = useGUniRouterContractUnsafe()
  const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface;
  const tokens = tokensWithDecimals.map(t => t.tokenAddress)
  const allowances = useMultipleContractSingleData(
      tokens,
      ERC20Interface,
      'allowance',
      [walletAddress, guniRouter?.address ? guniRouter?.address : ZERO_ADDRESS],
    )
  const poolsWithBalances = useMemo(() => allowances.map((allowance, index) => {
    const { result } = allowance;
    if (result) {
      const balance = result[0];
      if (balance.gt(ethers.constants.Zero)) {
        return {
          token: tokens[index],
          balance: balance.toString(),
        }
      }
    }
    return
  }), [allowances, tokens])

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }
  return poolsWithBalances.filter(notEmpty)
}