import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'

export const UNI_ADDRESS = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')
export const MULTICALL2_ADDRESSES = {
  [1]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [3]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [4]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [5]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [10]: '0x2DC0E2aa608532Da689e89e237dF582B783E552C',
  [137]: '0xa5b787b9489db81c0e1ef8e61de2067fbdc0db67',
  [250]: '0xd62741AAe5Ac680229881251d62c1DB461C566A4',
}

export const GUNI_FACTORY_ADDRESSES = {
  [1]: '0xEA1aFf9dbFfD1580F6b81A3ad3589E66652dB7D9',
  [3]: '0xCe23a1A2B2b18A2fEC300D3DB48f543E9d66BC08',
  [5]: '0x399cFce1F3f5AB74C46d9F0361BE18f87c23FCC3',
  [10]: '0x2845c6929d621e32B7596520C8a1E5a37e616F09',
  [137]: '0x37265A834e95D11c36527451c7844eF346dC342a',
}

export const GUNI_RESOLVER_ADDRESSES = {
  [1]: '0x0317650Af6f184344D7368AC8bB0bEbA5EDB214a',
  [3]: '',
  [5]: '0x78bfb478192bEFbb0D2ae01354d1362cF54F5D93',
  [10]: '0xd2Bb190dD88e7Af5DF176064Ec42f6dfA8672F40',
  [137]: '0x3638fc820c22b9ecd631943Bc7d5591C0004C7b2',
}

export const GUNI_ROUTER_COMPROMISED_ADDRESSES = {
  [1]: '0x14E6D67F824C3a7b4329d3228807f8654294e4bd',
  [10]: ''
}

export const GUNI_ROUTER_ADDRESSES = {
  [1]: '0x513E0a261af2D33B46F98b81FED547608fA2a03d',
  [3]: '',
  [5]: '0x899F86617B8D92A10aD8Fee92a6666e1dF84c037',
  [10]: '0xc56f04EC20dAD27c0f4701b14977C2DbE85142BA',
  [137]: '0x477E509B9d08862baEb8Ab69e901Ae72b13efcA0'
}

export const GUNI_CLAIM_ADDRESSES = {
  [1]: '0x57a7a432A159174D6c6e2004800a9ef5c13940b7',
  [3]: '',
  [5]: ''
}

export const V3_QUOTER_ADDRESSES = {
  [1]: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
  [3]: '',
  [5]: '0xf5Be6D3a408F06F00F2d6D4BB923fa9b695916f5',
  [10]: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
  [137]: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'
}

export const V2_ROUTER_ADDRESS = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
export const GOVERNANCE_ADDRESS = constructSameAddressMap('0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F')
export const TIMELOCK_ADDRESS = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId: number]: string } = {
  [1]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: { [chainId: number]: string } = {
  [1]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES = constructSameAddressMap(V3_FACTORY_ADDRESS)
export const QUOTER_ADDRESSES = constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6')
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = constructSameAddressMap(
  '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'
)
export const ENS_REGISTRAR_ADDRESSES = {
  [1]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [5]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [4]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [3]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES = {
  [1]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}
export const SWAP_ROUTER_ADDRESSES = constructSameAddressMap('0xE592427A0AEce92De3Edee1F18E0157C05861564')
export const V3_MIGRATOR_ADDRESSES = constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34')

export const NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'

export const UNISWAP_HELPERS_ADDRESSES = {
  [1]: '0xFbd0B8D8016b9f908fC9652895c26C5a4994fE36',
  [10]: '0x7D4a0231377a6CA320FF5f084b633a2e6B688107',
  [137]: '0x6F5bD53145AE657405C3d31dBA0fC6c5021Cb89A'
}