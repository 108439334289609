// used to mark unsupported tokens, these are hosted lists of unsupported tokens

const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
const QUICKSWAP_LIST = 'https://unpkg.com/quickswap-default-token-list@1.0.55/build/quickswap-default.tokenlist.json'
const PANCAKESWAP_LIST = 'https://raw.githubusercontent.com/complusnetwork/default-token-list/master/default-tokenlist-bsc.json';
const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS_MAINNET: string[] = [
  COMPOUND_LIST,
  COINGECKO_LIST,
  GEMINI_LIST,
  QUICKSWAP_LIST,
  OPTIMISM_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [GEMINI_LIST, OPTIMISM_LIST, QUICKSWAP_LIST, PANCAKESWAP_LIST]

export const DEFAULT_LIST_OF_LISTS_OPTIMISM: string[] = [PANCAKESWAP_LIST];
export const DEFAULT_LIST_OF_LISTS_POLYGON: string[] = [QUICKSWAP_LIST];
export const DEFAULT_LIST_OF_LISTS_BSC: string[] = [PANCAKESWAP_LIST];