import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import { ButtonMedium, ButtonSmall } from '../../components/Button'

const LegalDisclaimerPage: React.FC = () => {
  const history = useHistory()

  return (
    <div style={{ maxWidth: '480px', width: '100%', paddingBottom: '3rem' }}>
      <TYPE.description mb={3} mt={2}>
        Sorbet Finance or Gelato Network, thereafter known as “we”, “us” or “our”, is an experimental technology protocol that is helping Web3 projects to automate transactions and interact with DeFi applications. The technology is new and exciting, but also risky. By using our platform, you could potentially lose all of your money. If you do wish to continue, you consent to subscribe to our social media channels, including Twitter, Telegram and Discord. You alone are responsible for actively looking out for our announcements and being updated on any new developments.
      </TYPE.description>
      <TYPE.heading4 mb={3} mt={2}>Disclaimer</TYPE.heading4>
      <TYPE.description mb={3} mt={2}>
        <strong>We do not guarantee that our website will be secure or free from bugs or viruses. You will have no claim for refunds if any bug causes funds to be lost.</strong>
      </TYPE.description>
      <TYPE.description mb={3} mt={2}>
        <strong>You are responsible for configuring your information technology, computer programmes and platform to access our website. You should use your virus protection software.</strong>
      </TYPE.description>
      <TYPE.heading4 mb={3} mt={2}>NO FINANCIAL ADVICE</TYPE.heading4>
      <TYPE.description mb={3} mt={2}>
        The Information on our website is provided for educational, informational, and entertainment purposes only, without any express or implied warranty of any kind, including warranties of accuracy, completeness, or fitness for any particular purpose.
      </TYPE.description>
      <TYPE.description mb={3} mt={2}>
        The Information contained in or provided from or through this website or our social media channels is not intended to be and does not constitute financial advice, investment advice, trading advice, or any other advice.
      </TYPE.description>
      <TYPE.description mb={3} mt={2}>
        The Information on this website and provided from or through this website is general and is not specific to you, the user or anyone else. You should not make any decision, financial, investment, trading or otherwise, based on any of the information presented on this website without undertaking independent due diligence and consultation with a professional broker or financial advisory.
      </TYPE.description>
      <TYPE.description mb={3} mt={2}>
        You understand that you are using any Information available on or through this website at your own risk.
      </TYPE.description>
      <TYPE.heading4 mb={3} mt={2}>RISK STATEMENT</TYPE.heading4>
      <TYPE.description mb={3} mt={2}>
        The investment or trading of cryptocurrencies has potential rewards, and it also has potential risks involved. Trading may not be suitable for all people. Anyone wishing to invest should seek his or her own independent financial or professional advice.
      </TYPE.description>
      <TYPE.heading4 mb={3} mt={2}>ACCURACY OF INFORMATION</TYPE.heading4>
      <TYPE.description mb={3} mt={2}>
        We strive to ensure the accuracy of the information listed on this website although it will not hold any responsibility for any missing or wrong information. We provide all information as is. You understand that you are using any information available here at your own risk.
      </TYPE.description>
      <TYPE.heading4 mb={3} mt={2}>NON ENDORSEMENT</TYPE.heading4>
      <TYPE.description mb={5} mt={2}>
        The appearance of third party advertisements and hyperlinks on our website does not constitute an endorsement, guarantee, warranty, or recommendation by us. Do conduct your own due diligence before deciding to use any third party services.
      </TYPE.description>
      <ButtonMedium
        onClick={() => history.push('/')}
        style={{ width: '100%', maxWidth: '280px', padding: '10px', marginBottom: '2rem' }}
      >
        ← Back to home page
      </ButtonMedium>
    </div>
  )
}

export default LegalDisclaimerPage
