import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { PoolInfo, PoolDetails, PoolDetailsMap } from './reducer'
import { setPool, setDetails, updatePoolData, addPoolKeys } from './actions'
import { useActiveNetworkVersion } from 'state/application/hooks'

// Gets all pooldata for active network from redux state
export function useAllPoolData(): {
  [address: string]: { data: PoolInfo | undefined }
} {
  const [network] = useActiveNetworkVersion()
  return useSelector((state: AppState) => state.pool.byAddress[network.id] ?? undefined)
}

// Get pool data by address
export function usePoolData(address: string): { data: PoolInfo | undefined } {
  const [network] = useActiveNetworkVersion()
  return useSelector((state: AppState) => state.pool.byAddress[network.id][address])
}

export function useUpdatePoolData(): (pools: Array<PoolInfo>) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [network] = useActiveNetworkVersion()
  return useCallback(
    (pools: Array<PoolInfo>) => dispatch(updatePoolData({ pools, networkId: network.id })),
    [dispatch, network.id]
  )
}

export function useAddPoolKeys(): (addresses: string[]) => void {
  const dispatch = useDispatch<AppDispatch>()
  const [network] = useActiveNetworkVersion()
  return useCallback(
    (poolAddresses: string[]) => dispatch(addPoolKeys({ poolAddresses, networkId: network.id })),
    [dispatch, network.id]
  )
}

interface PoolReturnType {
  poolsData: Array<PoolInfo>
  setPoolsData: (poolsData: Array<PoolInfo>) => void
  poolsDetails: PoolDetailsMap
  setPoolDetails: (poolsDetails: PoolDetails) => void
}
export function usePool(): PoolReturnType {
  const [network] = useActiveNetworkVersion()
  const poolsData = useSelector<AppState, AppState['pool']['pools']>((state) => state.pool.pools)
  const poolsDetails = useSelector<AppState, AppState['pool']['poolsDetails']['network']>(
    (state) => state.pool.poolsDetails[network.id]
  )
  const dispatch = useDispatch<AppDispatch>()
  const setPoolsData = useCallback(
    (data: Array<PoolInfo>) => {
      dispatch(setPool({ pools: data }))
    },
    [dispatch]
  )
  const setPoolDetails = useCallback(
    (data: PoolDetails) => {
      dispatch(setDetails({ poolDetails: data, networkId: network.id }))
    },
    [dispatch, network.id]
  )
  return { poolsData, setPoolsData, poolsDetails, setPoolDetails }
}
