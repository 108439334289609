import { useMemo } from 'react'
import { ethers } from 'ethers'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useClients } from 'state/application/hooks'

// TODO Paginate to fetch every pool in case of totalPools > 1000
export const TOP_POOLS = gql`
  query {
    pools {
      id
    }
  }
`

interface TopPoolsResponse {
  pools: Array<{
    id: string
  }>
}

/**
 * Fetch top addresses by volume
 */
export function useTopPoolAddresses(): {
  loading: boolean
  error: boolean
  addresses: string[] | undefined
} {
  const { dataClient } = useClients()
  const { loading, error, data } = useQuery<TopPoolsResponse>(TOP_POOLS, {
    client: dataClient,
    fetchPolicy: 'cache-first',
  })

  const formattedData = useMemo(() => {
    if (data) {
      return data.pools.map((p: any) => ethers.utils.getAddress(p.id))
    } else {
      return undefined
    }
  }, [data])

  return {
    loading: loading,
    error: Boolean(error),
    addresses: formattedData,
  }
}
