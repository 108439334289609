import { Currency } from '@uniswap/sdk-core'
import { useActiveWeb3React } from 'hooks/web3'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Logo from '../Logo'
import { getBaseTokenLogoURLByTokenSymbol } from '../../constants/tokens'
import { useCombinedActiveList } from 'state/lists/hooks'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { chainId } = useActiveWeb3React()

  const activeTokenList = useCombinedActiveList()

  const srcs: string[] = useMemo(() => {
    if (!currency || currency.isNative) return []

    const uriBySymbol = getBaseTokenLogoURLByTokenSymbol(currency.symbol)

    if (currency.isToken) {
      const urlFromList =
        chainId && activeTokenList[chainId][currency.address]
          ? activeTokenList[chainId][currency.address]['token']['tokenInfo']['logoURI']
          : undefined
      const defaultUrls = currency.chainId === 1 ? [getTokenLogoURL(currency.address)] : []
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, ...defaultUrls]
      }
      const tokenUrlsSoFar = uriBySymbol ? [uriBySymbol, ...defaultUrls] : defaultUrls
      return urlFromList ? ([urlFromList, ...tokenUrlsSoFar] as string[]) : (tokenUrlsSoFar as string[])
    }
    return []
  }, [currency, uriLocations, chainId, activeTokenList])
  if (currency?.symbol == "G-UNI") {
    return (<StyledLogo
      srcs={['https://i.ibb.co/sy9Dc1z/G-UNI-Logo.png']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "ETHV" || currency?.symbol == "iETHV") {
    return (<StyledLogo
      srcs={['https://raw.githubusercontent.com/volmexfinance/assets/main/logo.png']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "agEUR") {
    return (<StyledLogo
      srcs={['https://app.angle.money/static/media/agEUR.5d8c8896.svg']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "GEL") {
    return (<StyledLogo
      srcs={['https://aws1.discourse-cdn.com/standard20/uploads/gelatoforum/original/1X/aeecb1cdf357282282e58530a2960bf1750becb0.jpeg']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "POP") {
    return (<StyledLogo
      srcs={['https://popcorn.network/android-chrome-192x192.png']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "THALES") {
    return (<StyledLogo
      srcs={['https://i.ibb.co/NFzQxYB/01-Thales-Symbol-on-Primary.png']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "ETHMAXY") {
    return (<StyledLogo
      srcs={['https://assets.coingecko.com/coins/images/24047/large/ethmaxy.png?1646194193']}
      size={size}
      style={style}
      {...rest}
    />)
  } else if (currency?.symbol == "JPG") {
    return (<StyledLogo
      srcs={['https://assets.coingecko.com/coins/images/25071/thumb/JPG-token-logo-01.png?1650242041']}
      size={size}
      style={style}
      {...rest}
    />)
  }
  if (currency?.isNative) {
    return chainId !== 1 ? (
      <StyledLogo
        srcs={[getBaseTokenLogoURLByTokenSymbol(currency.symbol) ?? '']}
        size={size}
        style={style}
        {...rest}
      />
    ) : (
      <StyledEthereumLogo src={EthereumLogo} size={size} style={style} {...rest} />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} {...rest} />
}
