import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import spiceCapsuleImageSrc from '../../assets/images/spice-capsule.gif'

const bannerHeightMobile = '64px'
const bannerHeightDesktop = '100px'

const Root = styled.div<{ isHidden: boolean }>`
  position: relative;
  justify-content: center;
  width: 100%;
  height: ${bannerHeightMobile};
  @media (min-width: 900px) {
    height: ${bannerHeightDesktop};
  }
  overflow: hidden;
  color: #ffffff;
  background: #000000;

  &:hover img {
    transform: translate3d(0, -11.75%, 0) rotate(-15deg) scale(1.094);
    transition-timing-function: ease-out;
  }

  transition: transform 100ms ease-in;

  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
`

const Container = styled.div`
  height: 100%;
  margin-left: 0px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 900px) {
    margin-right: 0;
    margin-left: 20px;
  }
`
const Icon = styled.img`
  display: block;
  margin-top: 15px;
  width: 80px;
  height: 80px;
  display: none;
  @media (min-width: 360px) {
    display: block;
  }
  @media (min-width: 900px) {
    margin-top: 25px;
    width: 128px;
    height: 128px;
  }

  transition: transform 400ms ease-in;
`
const Text = styled.div`
  position: relative;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  @media (min-width: 360px) {
    margin-left: 0px;
  }
  @media (min-width: 900px) {
    margin-right: 25px;
    font-size: 18px;
    line-height: 20px;
  }

  & strong {
    font-weight: 400;
    color: #fb8547;
  }
`
const Timer = styled.div`
  margin-top: 0px;
  color: #f9f9f9;
  opacity: 0.6;
  text-align: left;
  font-size: 12px;
  @media (min-width: 900px) {
    margin-top: 4px;
    font-size: 16px;
    text-align: center;
  }

  & span {
    display: inline-block;
    text-align: left;
    margin-right: -10px;
    @media (min-width: 900px) {
      min-width: 125px;
    }
  }
`
const Link = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  & span {
    display: none;
  }
  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 20px;

    & span {
      display: inline;
    }
  }
  padding: 8px 16px;
  border-radius: 40px;
  background: #ffffff;
  color: #000000;
  transition: background-color 200ms ease-in;

  &:hover {
    background: #e6e6e6;
    transition-timing-function: ease-out;
  }
`
const Close = styled.button`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  height: 46px;
  width: 46px;
  min-width: 0;
  padding: 14px;
  background: none;
  border: none;
  color: #ffffff;
  transition: opacity 200ms ease-in;
  cursor: pointer;

  @media (min-width: 900px) {
    position: absolute;
    right: 12px;
  }

  &:hover {
    background: none;
    opacity: 0.7;
    transition-timing-function: ease-out;
  }

  & svg {
    display: block;
  }
`

const LOCK_AVAILIBLE_DATE = 'Friday, June 10, 2022 3:00:00 PM GMT'
const GEL_LOCK_LICK = 'https://gov.gelato.network/spice-airdrop'
const announcementDisabledKey = 'gel-lock-announcement-dismissed'

export function useAnnouncementBanner() {
  const isDisabled = window.sessionStorage.getItem(announcementDisabledKey) === 'true'
  const [isClosed, setIsClosed] = useState(false)
  const closeAnnouncement = useCallback(() => {
    window.sessionStorage.setItem(announcementDisabledKey, 'true')
    setIsClosed(true)
  }, [])

  return {
    isDisabled,
    isClosed,
    closeAnnouncement,
  }
}

function useCountdown(isDisabled: boolean) {
  const [isFinished, setIsFinished] = useState(isDisabled)
  const [remainingTime, setRemainingTime] = useState<string>('')
  const countDownDate = new Date(LOCK_AVAILIBLE_DATE).getTime()

  const timerId = setInterval(function () {
    calcRemainingTime()
  }, 1000)

  function calcRemainingTime() {
    const now = new Date().getTime()
    const distance = countDownDate - now
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    if (distance >= 0 && !isDisabled) {
      setRemainingTime(days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's')
    } else {
      setRemainingTime('')
      setIsFinished(true)
      timerId && clearInterval(timerId)
    }
  }

  useEffect(() => {
    calcRemainingTime()
  }, [])

  return {
    remainingTime,
    isFinished,
  }
}

const getAnnouncementBannerGlobalCss = (isHidden: boolean) => `
  :root {
    --body-wrapper-margin: ${isHidden ? '0px' : bannerHeightMobile};
  }
  @media (min-width: 900px) {
    :root {
      --body-wrapper-margin: ${isHidden ? '0px' : bannerHeightDesktop};
    }
  }
`

export default function AnnouncementBanner() {
  const { isDisabled, isClosed, closeAnnouncement } = useAnnouncementBanner()
  const { isFinished, remainingTime } = useCountdown(isDisabled)
  const isHidden = isDisabled || isClosed || isFinished

  return (
    <Root isHidden={isHidden}>
      <style>{getAnnouncementBannerGlobalCss(isHidden)}</style>
      <Container>
        <Icon src={spiceCapsuleImageSrc} alt="" />
        <Text>
          Lock your $GEL now to&nbsp;receive&nbsp;the&nbsp;<strong>$SPICE</strong> Airdrop
          <Timer>
            Locking closes in <span>{remainingTime}</span>
          </Timer>
        </Text>
        <Link href={GEL_LOCK_LICK} target="_blank">
          Lock <span>$GEL</span>
        </Link>
      </Container>
      <Close onClick={closeAnnouncement}>
        <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m1 1 8 8m8 8L9 9m0 0-8 8m8-8 8-8" stroke="#fff" />
        </svg>
      </Close>
    </Root>
  )
}
