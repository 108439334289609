import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter';
import Header from '../components/Header';
import Polling from '../components/Header/Polling';
import Popups from '../components/Popups';
import LegalDisclaimerPage from './Legal/LegalDisclaimerPage';
import Web3ReactManager from '../components/Web3ReactManager';
import ErrorBoundary from '../components/ErrorBoundary';
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader';
import LimitOrder from './LimitOrder';
import RangeOrder from './RangeOrder';
import { RedirectPathToSwapOnly, RedirectToSwap } from './LimitOrder/redirects';
import { ThemedBackground } from '../theme';
import PoolRouterV3 from './PoolV3/PoolRouter';
import ClaimPageA from './Claim/ClaimPageA';
import ClaimPageB from './Claim/ClaimPageB';
import ClaimPageC from './Claim/ClaimPageC';
import { QueryClient, QueryClientProvider } from 'react-query';
import CheckWalletPage from './Claim/CheckWalletPage';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const BodyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(120px + var(--body-wrapper-margin));
  width: 100%;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: calc(120px + var(--body-wrapper-margin)) 16px 16px 16px;
  `};
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

const client = new QueryClient();
export default function App() {
    return (
        <ErrorBoundary>
            <Suspense fallback={null}>
                <Route component={GoogleAnalyticsReporter} />
                <Route component={DarkModeQueryParamReader} />
                <AppWrapper>
                    <HeaderWrapper>
                        <Header />
                    </HeaderWrapper>
                    <BodyWrapper>
                        <ThemedBackground />
                        <Popups />
                        <Polling />
                        <Web3ReactManager>
                            <QueryClientProvider client={client}>
                                <div style={{ fontWeight: 'bold', width: '50%', textAlign: 'center', margin: '40px 0', display: 'block'}}>
                                    <p>Sorbet.finance is closing 31st March 2023</p>
                                    <p>
                                      You can no longer open new orders. Please cancel all your open orders before
                                      31st March as they won’t be executed after this date. Please reach out to us if
                                      you have any questions.
                                    </p>
                                </div>
                                <Switch>
                                    <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                                    <Route exact strict path="/swap" component={RedirectPathToSwapOnly} />
                                    <Route exact strict path="/limit-order/:outputCurrency" component={RedirectToSwap} />
                                    <Route exact strict path="/limit-order" component={LimitOrder} />
                                    <Route exact strict path="/range-order" component={RangeOrder} />
                                    <Route path="/pools" component={PoolRouterV3} />
                                    <Route path="/revoke-tokens" component={ClaimPageA} />
                                    <Route path="/claim-tokens" component={ClaimPageB} />
                                    <Route path="/claim-success" component={ClaimPageC} />
                                    <Route path="/check-wallet" component={CheckWalletPage} />
                                    <Route path="/legal-disclaimer" component={LegalDisclaimerPage} />
                                    <Route component={RedirectPathToSwapOnly} />
                                </Switch>
                            </QueryClientProvider>
                        </Web3ReactManager>
                        <Marginer />
                    </BodyWrapper>
                </AppWrapper>
            </Suspense>
        </ErrorBoundary>
    );
}
