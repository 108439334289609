import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PoolLayout from './components/PoolLayout'
import { PoolsSwitchToArrakis, PoolsPlaceholder } from './module/PoolsSwitchToArrakis'
// import PoolList from './module/PoolList'
// import PoolOverview from './module/PoolOverview'
// import AddLiquidity from './module/AddLiquidity'
// import AddLiquidityConfirm from './module/AddLiquidity/AddLiquidityPanel'
// import RemoveLiquidity from './module/RemoveLiquidity'
// import CreatePool from './module/CreatePool'
import BannerWrapper from '../Claim/BannerWrapper'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { GelatoProvider } from './index'

function Gelato({ children }: { children?: React.ReactNode }) {
  const { library, chainId, account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <GelatoProvider
      library={library}
      chainId={chainId}
      account={account ?? undefined}
      toggleWalletModal={toggleWalletModal}
    >
      {children}
    </GelatoProvider>
  )
}

function PoolRoute() {
  const { path } = useRouteMatch()
  return (
    <Gelato>
      <BannerWrapper />
      <PoolsSwitchToArrakis />
      <PoolLayout>
        <Switch>
          <Route exact path={path} component={PoolsPlaceholder} />
          {/* <Route exact path={path} component={PoolList} />
          <Route exact path={`${path}/create`} component={CreatePool} />
          <Route exact path={`${path}/:address`} component={PoolOverview} />
          <Route exact path={`${path}/:address/add/confirm`} component={AddLiquidityConfirm} />
          <Route exact path={`${path}/:address/add`} component={AddLiquidity} />
          <Route exact path={`${path}/:address/remove`} component={RemoveLiquidity} /> */}
        </Switch>
      </PoolLayout>
    </Gelato>
  )
}

export default PoolRoute
