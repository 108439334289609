import React, { useState } from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../../components/CurrencyLogo'
import './index.css'
import { useTokenContract } from '../../hooks/useContract'
import { ethers } from 'ethers'
import Logo from '../../components/Logo'
import { TYPE } from '../../theme'
import { useCurrency } from '../../hooks/Tokens'

export const TokenWrapper = styled.div<{ margin?: string }>`
  background: ${({ theme }) => theme.bg1};
  display: flex;
  justify-content: space-between;
  align-items: center;  
  border-radius: 24px;
  padding: 20px 18px;
  margin-top: ${({ margin }) => margin ?? '0px'};
  margin-top: 1rem;
  max-width: 500px;
  position: relative;
  width: 100%;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export function ClaimTokenItem({ tokenAddress, balance }: {
  tokenObj?: any,
  tokenAddress: string,
  balance?: number,
}) {

  const token = useTokenContract(tokenAddress)
  const [symbol, setSymbol] = useState<string>()
  const currency = useCurrency(ethers.utils.getAddress(tokenAddress))

  async function getSymbol() {
    const symbol = await token?.symbol();
    setSymbol(symbol)
  }

  getSymbol()

  return (
    <TokenWrapper key={tokenAddress}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {currency ? <CurrencyLogo currency={currency} size={'34px'} />
          : <StyledLogo size={'34px'} srcs={[tokenAddress || '']} />}
        <div style={{ marginLeft: '10px' }}>
          {symbol ? symbol : 'Unknown token'}
        </div>
      </div>
      <TYPE.description>Balance in escrow: {balance}</TYPE.description>
    </TokenWrapper>
  )
}
