import { gql } from '@apollo/client'

// PoolFields is used to load raw data fetched from thegraph
export interface PoolFields {
  id: string
  name: string
  blockCreated: string
  address: string
  manager: string
  uniswapPool: string
  positionId: string
  sqrtPrice: string
  tick: string
  token0: {
    address: string
    symbol: string
    decimals: string
  }
  token1: {
    address: string
    symbol: string
    decimals: string
  }
  feeTier: string
  liquidity: string
  lowerTick: string
  upperTick: string
  totalSupply: string
  managerFee: string
  supplySnapshots: Array<{
    id: string
    block: string
    reserves0: string
    reserves1: string
    sqrtPriceX96: string
  }>
  feeSnapshots: Array<{
    id: string
    block: string
    feesEarned0: string
    feesEarned1: string
  }>
  latestInfo: {
    sqrtPriceX96: string
    reserves0: string
    reserves1: string
    leftover0: string
    leftover1: string
    unclaimedFees0: string
    unclaimedFees1: string
    block: string
  }
}

const POOL_FIELDS = gql`
  fragment PoolFields on Pool {
    id
    name
    blockCreated
    manager
    address
    uniswapPool
    positionId
    token0 {
      address
      symbol
      decimals
    }
    token1 {
      address
      symbol
      decimals
    }
    feeTier
    liquidity
    lowerTick
    upperTick
    totalSupply
    managerFee
    supplySnapshots {
      id
      block
      reserves0
      reserves1
      sqrtPriceX96
    }
    feeSnapshots {
      id
      block
      feesEarned0
      feesEarned1
    }
    latestInfo {
      sqrtPriceX96
      reserves0
      reserves1
      leftover0
      leftover1
      unclaimedFees0
      unclaimedFees1
      block
    }
  }
`
export const POOLS_BULK = gql`
  ${POOL_FIELDS}
  query {
    pools {
      ...PoolFields
    }
  }
`

export const MyPools = gql`
  query PoolBalances($user: String) {
    poolBalances(where: { user: $user }) {
      id
      pool {
        id
      }
    }
  }
`

export interface MyPoolsFields {
  id: string
  pool: {
    id: string
  }
}
