import React from 'react'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { useIsDarkMode } from '../../../../state/user/hooks'

import GuniToArrakisImage from '../../../../assets/svg/g-uni-to-arrakis.svg'
import { ReactComponent as ArrakisIcon } from '../../../../assets/svg/arrakis-icon.svg'
import PoolsPlaceholderDarkImage from '../../../../assets/images/pools-placeholder-dark.png'
import PoolsPlaceholderLightImage from '../../../../assets/images/pools-placeholder-light.png'

const PoolContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
`

const SwitchToArrakisBanner = styled('div')`
  background: #373737;
  border-radius: 24px;
  width: 95vw;
  max-width: 560px;
  padding: 38px 35px 51px;
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 20px;
  `};

  img {
    max-width: 100%;
  }

  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0 30px;
    margin: 22px 0 40px;
  }

  & > a {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    padding: 10px 50px 10px 34px;
    border: 1px solid transparent;
    border-radius: 20px;
    z-index: 0;

    ${({ theme }) => theme.mediaWidth.upToMedium`
      padding-left: 28px;
      padding-right: 33px;
    `};

    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 20px;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: linear-gradient(90deg, #deb08d 0%, #a8876e 81%);
      z-index: -2;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: linear-gradient(90deg, #393938 0%, #71655c 50%, #eab892 100%);
      z-index: -1;

      background: radial-gradient(circle, #eab892 0%, #71655c 50%, #393938 100%);
      background-size: 200% 200%;
      animation: lock-animate 30s linear infinite;

      @keyframes lock-animate {
        0% {
          background-position: 0% 400%;
        }
        50% {
          background-position: 100% 200%;
        }
        100% {
          background-position: 0% 400%;
        }
      }

      opacity: 1;
      transition: opacity 300ms ease-in;
    }

    &:hover:after {
      opacity: 0.8;
      transition-timing-function: ease-out;
    }

    svg {
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 32px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 999px;

      ${({ theme }) => theme.mediaWidth.upToMedium`
        margin-right: 22px;
      `};
    }
  }
`

export const PoolsSwitchToArrakis: React.FC = () => {
  return (
    <PoolContainer>
      <SwitchToArrakisBanner>
        <img src={GuniToArrakisImage} alt="G-UNI pools move to Arrakis" />
        <TYPE.description>
          G-UNI has been rebranded as Arrakis Finance
          <br /> please find all the vaults and your liquidity position here
        </TYPE.description>
        <a href="https://beta.arrakis.finance/">
          <ArrakisIcon />
          Enter Arrakis Vaults
        </a>
      </SwitchToArrakisBanner>
    </PoolContainer>
  )
}

const PoolsPlaceholderImage = styled('img')`
  pointer-events: none;
  user-select: none;
  max-width: 100%;
`

export const PoolsPlaceholder: React.FC = () => {
  const darkMode = useIsDarkMode()
  return <PoolsPlaceholderImage src={darkMode ? PoolsPlaceholderDarkImage : PoolsPlaceholderLightImage} alt="" />
}
