import React from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import { ButtonMedium } from '../../components/Button'

const ClaimPageB: React.FC = () => {
  const history = useHistory()

  return (
    <div style={{ maxWidth: '480px', width: '100%', paddingBottom: '3rem' }}>
      <TYPE.heading1 mb={2} mt={2}>Complete!</TYPE.heading1>
      <TYPE.description mb={4} mt={2}>Thanks for your vigilance. Note: This was not a security issue in the G-UNI core smart contracts, but in another smart contract that interacts with the system. Your G-UNI tokens are safe as always.</TYPE.description>
      <ButtonMedium
        onClick={() => history.push('/')}
        style={{ width: '100%', maxWidth: '280px', padding: '10px', marginBottom: '2rem' }}
      >
        ← Back to home page
      </ButtonMedium>
    </div>
  )
}

export default ClaimPageB
