import { useQuery } from '@apollo/client'
import { ethers } from 'ethers'
import { PoolInfo } from 'state/pool/reducer'
import { formatTokenSymbol } from 'utils/tokens'
import { useClients } from 'state/application/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { PoolFields, POOLS_BULK } from 'queries/pools'
import { useMemo } from 'react'

interface PoolDatasReturn {
  loading: boolean
  error: boolean
  data:
    | {
        [address: string]: PoolInfo
      }
    | undefined
}

/**
 * Fetch top addresses by volume
 */
export function usePoolDatas(poolAddresses: string[]): PoolDatasReturn {
  // get client
  const { dataClient } = useClients()

  const { loading, error, data } = useQuery<{ pools: PoolFields[] }>(POOLS_BULK, {
    client: dataClient,
  })

  const anyError = Boolean(error)
  const anyLoading = Boolean(loading)

  // format data
  const formatted = useMemo(() => {
    const parsed = data?.pools
      ? data.pools.reduce((accum: { [address: string]: PoolFields }, poolData) => {
          accum[ethers.utils.getAddress(poolData.id)] = poolData
          return accum
        }, {})
      : {}

    return poolAddresses.reduce((accum: { [address: string]: PoolInfo }, address) => {
      const current: PoolFields | undefined = parsed[address]
      const feeTier = current ? parseInt(current.feeTier) : 0

      if (current) {
        accum[ethers.utils.getAddress(address)] = {
          id: ethers.utils.getAddress(current.id),
          address: ethers.utils.getAddress(address),
          name: current.name,
          blockCreated: parseInt(current.blockCreated),
          feeSnapshots: current.feeSnapshots.map((fs) => ({
            id: fs.id,
            block: parseInt(fs.block),
            feesEarned0: BigNumber.from(fs.feesEarned0),
            feesEarned1: BigNumber.from(fs.feesEarned1),
          })),
          feeTier,
          liquidity: parseFloat(current.liquidity),
          lowerTick: Number(current.lowerTick),
          upperTick: Number(current.upperTick),
          manager: current.manager,
          managerFee: parseFloat(current.managerFee),
          positionId: current.positionId,
          supplySnapshots: current.supplySnapshots.map((ss) => ({
            block: parseInt(ss.block),
            id: ss.id,
            reserves0: BigNumber.from(ss.reserves0),
            reserves1: BigNumber.from(ss.reserves1),
            sqrtPriceX96: BigNumber.from(ss.sqrtPriceX96),
          })),
          token0: {
            address: current.token0.address,
            symbol: formatTokenSymbol(current.token0.address, current.token0.symbol),
            decimals: parseInt(current.token0.decimals),
          },
          token1: {
            address: current.token1.address,
            symbol: formatTokenSymbol(current.token1.address, current.token1.symbol),
            decimals: parseInt(current.token1.decimals),
          },
          totalSupply: BigNumber.from(current.totalSupply),
          uniswapPool: current.uniswapPool,
          latestInfo: {
            block: parseInt(current.latestInfo.block),
            leftover0: BigNumber.from(current.latestInfo.leftover0),
            leftover1: BigNumber.from(current.latestInfo.leftover1),
            reserves0: BigNumber.from(current.latestInfo.reserves0),
            reserves1: BigNumber.from(current.latestInfo.reserves1),
            sqrtPriceX96: BigNumber.from(current.latestInfo.sqrtPriceX96),
            unclaimedFees0: BigNumber.from(current.latestInfo.unclaimedFees0),
            unclaimedFees1: BigNumber.from(current.latestInfo.unclaimedFees1),
          },
          sqrtPrice: parseFloat(current.sqrtPrice),
        }
      }

      return accum
    }, {})
  }, [data?.pools, poolAddresses])

  return {
    loading: anyLoading,
    error: anyError,
    data: anyError || anyLoading ? undefined : formatted,
  }
}
