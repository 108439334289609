import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import AttentionBanner from './AttentionBanner'
import { useGUniClaimContract } from '../../hooks/useContract'
import { tokensWithDecimals } from '../../constants/tokenLists/claimTokens'
import ClaimBanner from './ClaimBanner'
import SafeBanner from './SafeBanner'
import { RevokeTokenItem } from './RevokeTokenItem'
import { useActiveWeb3React } from '../../hooks/web3'
import { useRevokePools } from 'hooks/useRevokePools'


const PoolBanner = ({ account }: any) => {
  const filteredPoolsWithBalances = useRevokePools(account);
  const guniClaim = useGUniClaimContract()
  const [balances, setBalances] = useState<any[]>([])
  useEffect(() => {
    const getClaims = async () => {
      if (guniClaim) {

        const amounts = await Promise.all(
          tokensWithDecimals.map(async token => {
            return new Promise(async (resolve) => {
              const tx = await guniClaim.amountWasAtRisk(token.tokenAddress, account)
              // token.decimals()
              resolve(
                {
                  token: token.tokenAddress,
                  amount: parseFloat(ethers.utils.formatUnits(tx, 6))
                }
              )
            })
          })
        )
        setBalances(amounts)
      }
    }

    if (account) getClaims()
  }, [guniClaim, account])
  return(
    <>
      {(
        (filteredPoolsWithBalances && filteredPoolsWithBalances.length > 0)
        || (balances && balances.find((a: any) => a.amount !== 0.0))
      ) && <ClaimBanner account={account} revokeTokens={filteredPoolsWithBalances} balances={balances} />
      }
      <div style={{ display: 'none' }}>
        {filteredPoolsWithBalances.map((token: any) => {
          return <RevokeTokenItem
            tokenAddress={token.token}
            key={token.token}
          />
        })}
      </div>
    </>
  )
}

const BannerWrapper = () => {
  const { account, chainId } = useActiveWeb3React()

  return (<>
      {
        (account && chainId === 1) && <PoolBanner account={account} />
      }
    </>
  )
}

export default BannerWrapper