import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import CurrencyLogo from '../../components/CurrencyLogo'
import { ButtonSmall } from '../../components/Button'
import './index.css'
import { useGUniRouterContractUnsafe, useTokenContract } from '../../hooks/useContract'
import { ethers } from 'ethers'
import Logo from '../../components/Logo'
import { useActiveWeb3React } from '../../hooks/web3'
import { useCurrency } from 'hooks/Tokens'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useWalletModalToggle } from 'state/application/hooks'

export const TokenWrapper = styled.div<{ margin?: string }>`
  background: ${({ theme }) => theme.bg1};
  display: flex;
  justify-content: space-between;
  align-items: center;  
  border-radius: 24px;
  padding: 20px 18px;
  margin-top: ${({ margin }) => margin ?? '0px'};
  margin-top: 1rem;
  max-width: 500px;
  position: relative;
  width: 100%;
`

const fullRotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  animation: ${fullRotation} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.white};
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export function RevokeTokenItem({ tokenAddress }: { tokenAddress: string }) {
  const { account } = useActiveWeb3React()
  const guniRouter = useGUniRouterContractUnsafe()
  const token = useTokenContract(tokenAddress)
  const [symbol, setSymbol] = useState<string>()
  const currency = useCurrency(ethers.utils.getAddress(tokenAddress))
  const addTransaction = useTransactionAdder()
  const toggleWalletConnect = useWalletModalToggle()

  async function getSymbol() {
    const symbol = await token?.symbol();
    setSymbol(symbol)
  }

  getSymbol()

  const [loading, setLoading] = useState<boolean>(false)
  const [revoked, setRevoked] = useState<boolean>(false)

  const handleRevoke = async () => {
    if (token && guniRouter) {
      const tx = await token.approve(guniRouter.address, ethers.constants.Zero)
      setLoading(true)
      addTransaction(tx)
      const receipt = (await tx?.wait()) as any
      if (receipt) {
        setLoading(false)
        setRevoked(true);
      }
    }
  }

  return (
    <TokenWrapper key={tokenAddress}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {currency ? <CurrencyLogo currency={currency} size={'34px'} />
          : <StyledLogo size={'34px'} srcs={[tokenAddress || '']} />}
        <div style={{ marginLeft: '10px' }}>
          {symbol ? symbol : 'Unknown token'}
        </div>
      </div>
      {
        account ? (
          <ButtonSmall
            onClick={() => handleRevoke()}
            style={{ width: '180px', padding: '10px' }}
            className="small-padding-on-mobile"
            disabled={revoked}
          >
            {loading ? <Spinner /> : revoked ? 'Revoked' : 'Revoke approval'}
          </ButtonSmall>
        ) : (
          <ButtonSmall
            onClick={() => toggleWalletConnect()}
            style={{ width: '180px', padding: '10px' }}
          >
            Connect wallet
          </ButtonSmall>
        )
      }
    </TokenWrapper>
  )
}
