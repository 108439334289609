import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TYPE } from 'theme'
import styled, { keyframes } from 'styled-components'
import { ButtonMedium } from '../../components/Button'
import { ClaimTokenItem } from './ClaimTokenItem'
import { useGUniClaimContract } from 'hooks/useContract'
import Logo from '../../components/Logo'
import { ethers } from 'ethers'
import { useActiveWeb3React } from '../../hooks/web3'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { tokensWithDecimals as tokens } from 'constants/tokenLists/claimTokens'

export const TokenWrapper = styled.div<{ margin?: string }>`
  background: ${({ theme }) => theme.bg1};
  display: flex;
  justify-content: space-between;
  align-items: center;  
  border-radius: 24px;
  padding: 20px 18px;
  margin-top: ${({ margin }) => margin ?? '0px'};
  margin-top: 1rem;
  max-width: 500px;
  position: relative;
  width: 100%;
`

const fullRotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div`
  animation: ${fullRotation} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);

  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.white};
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

const ClaimPageB: React.FC = () => {
  const history = useHistory()
  const guniClaim = useGUniClaimContract()
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const [loading, setLoading] = useState<boolean>(false)
  const handleClaimAll = async () => {
    if (guniClaim) {
      const balancesToClaim = balances
        .filter(tokenObj => parseFloat(tokenObj.amount) !== 0)
        .map(b => b.token)
      const estimatedGas = await guniClaim.estimateGas.claim(balancesToClaim)
      const tx = await guniClaim.claim(balancesToClaim, {
        gasLimit: estimatedGas.add(ethers.BigNumber.from('50000')),
      })
      setLoading(true)
      addTransaction(tx)
      if (tx) {
        const receipt = (await tx?.wait()) as any
        if (receipt) {
          setLoading(false)
          history.push('/claim-success')
        }
      }
    }
  }

  const [balances, setBalances] = useState<any[]>([])
  useEffect(() => {
    const getClaims = async () => {
      if (guniClaim) {
        const formatNumber = (amount: string) => {
          const number = parseFloat(amount)
          if (number < 1) {
            return number.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 6 })
          } else {
            return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
          }
        }

        const amounts = await Promise.all(
          tokens.map(async token => {
            return new Promise(async (resolve) => {
              const tx = await guniClaim.amountWasAtRisk(token.tokenAddress, account)
              // token.decimals()
              resolve(
                {
                  token: token.tokenAddress,
                  amount: formatNumber(ethers.utils.formatUnits(tx, token.decimals))
                }
              )
            })
          })
        )
        setBalances(amounts)
      }
    }

    getClaims()
  }, [guniClaim])


  return (
    <div style={{ maxWidth: '480px', width: '100%', paddingBottom: '3rem' }}>
      <TYPE.heading1 mb={2} mt={2}>Step 2/2</TYPE.heading1>
      <TYPE.heading6 mb={2} mt={2}>Claim my tokens</TYPE.heading6>
      <TYPE.description mb={4} mt={2}>Now you can claim all of your tokens back in one transaction.</TYPE.description>
      <ButtonMedium
        onClick={handleClaimAll}
        disabled={balances.filter(tokenObj => parseFloat(tokenObj.amount) !== 0).length === 0}
        style={{ width: '100%', maxWidth: '280px', padding: '10px', marginBottom: '2rem' }}
      >
        {loading ? <Spinner /> : 'Claim tokens'}
      </ButtonMedium>
      {balances.filter(tokenObj => parseFloat(tokenObj.amount) !== 0).length > 0
        ? balances
          .filter(tokenObj => parseFloat(tokenObj.amount) !== 0)
          .map(tokenObj => {
          return <ClaimTokenItem
            tokenAddress={tokenObj.token}
            key={tokenObj.token}
            balance={tokenObj.amount}
          />
          })
        : <div>No balances to claim</div>}
    </div>
  )
}

export default ClaimPageB
